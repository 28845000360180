<template>
  <div>
    <b-button
      v-if="!hiddenContent"
      size="sm"
      class="button-export-style  ml-2"
      title="Valider des factures"
      @click.prevent="FacuresToConfirme"
      ><font-awesome-icon icon="check-double" class="file-medical"
    /></b-button>
    <v-tooltip bottom color="#311B92" v-else>
      <template v-slot:activator="{ on }">
        <div
          class="block-icon mr-2"
          v-on="on"
          @click.prevent="FacuresToConfirme"
        >
          <IconBadgeCheck></IconBadgeCheck>
        </div>
      </template>
      <span>Valider des factures</span>
    </v-tooltip>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  props: {
    dataToUse: { required: true },
    hiddenContent: { required: false },
    type: { reduired: true }
  },
  components: {
    IconBadgeCheck: () => import('@/assets/img/IconBadgeCheck.vue')
  },
  methods: {
    ...mapActions(['validateFactureSci', 'validateMultiFactureMensuel']),
    async FacuresToConfirme() {
      let ids = [];
      let htmlMessage =
        "<div  style='margin: auto;width: 50%;height: 250px;overflow-x: auto;' class='ModalScrol'><div> ";

      for (let i = 0; i < this.dataToUse.length; i++) {
        for (let j = 0; j < this.dataToUse[i].factures.length; j++) {
          if (
            this.dataToUse[i].factures[j] &&
            this.dataToUse[i].factures[j].check == true &&
            this.dataToUse[i].factures[j].id &&
            this.dataToUse[i].factures[j].etat != 'Annulée'
          ) {
            ids.push(this.dataToUse[i].factures[j]);
            htmlMessage =
              htmlMessage +
              '<li>' +
              this.dataToUse[i].factures[j].num +
              '</li>';
          }
        }
      }
      htmlMessage = htmlMessage + '</div></div>';

      this.$swal
        .fire({
          title: 'Êtes-vous sur de confirmer ces factures ',
          type: 'warning',
          icon: 'warning',
          locale: 'fr',
          allowOutsideClick: () => !this.$swal.isLoading(),
          stopKeydownPropagation: false,
          confirmButtonText: 'OUI',
          cancelButtonText: 'NON',
          showCancelButton: true,
          confirmButtonClass: 'btn btn-success-alert',
          cancelButtonClass: 'btn btn-danger-alert',
          cancelButtonColor: '',
          confirButtonColor: '',
          html: htmlMessage,
          customClass: {
            actions: 'my-actions',
            cancelButton: 'order-2 ',
            confirmButton: 'order-1'
          },
          preConfirm: () => {
            this.$swal.showLoading();
          }
        })
        .then(async result => {
          if (this.type == 'sci') {
            if (result.isConfirmed) {
              const response = await this.validateFactureSci(ids);
            }
          } else if (this.type == 'mensuel') {
            if (result.isConfirmed) {
              const response = await this.validateMultiFactureMensuel(ids);
            }
          }
        });
    }
  }
};
</script>

<style></style>
